.watermark {
  @include abs(0);

  z-index: 1;
  opacity: 0.2;
  color: white;
  pointer-events: none;
  aspect-ratio: 1 / 1;
  margin-left: auto;
  margin-top: auto;
}
