// See content-block-testimonials for an example usage

.swiper-pagination {
  display: flex;
  gap: 4px;
  align-items: center;

  .swiper-pagination-bullet {
    --size: 0.8em;
    height: var(--size);
    width: var(--size);
    border-radius: 50%;
    background: var(--bs-primary-100);

    &.swiper-pagination-bullet-active {
      background: var(--bs-primary);
    }
  }
}
