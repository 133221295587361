//! this is a modified version of bootstrap's reboot.scss

// stylelint-disable declaration-no-important, selector-no-qualifying-type, property-no-vendor-prefix

// Reboot
//
// Normalization of HTML elements, manually forked from Normalize.css to remove
// styles targeting irrelevant browsers while applying new styles.
//
// Normalize is licensed MIT. https://github.com/necolas/normalize.css

// Document
//
// Change from `box-sizing: content-box` so that `width` is not affected by `padding` or `border`.

*,
*::before,
*::after {
  box-sizing: border-box;
}

// Root
//
// Ability to the value of the root font sizes, affecting the value of `rem`.
// null by default, thus nothing is generated.

:root {
  @if $font-size-root != null {
    @include font-size(var(--#{$prefix}root-font-size));
  }

  @if $enable-smooth-scroll {
    @media (prefers-reduced-motion: no-preference) {
      scroll-behavior: smooth;
    }
  }
}

// Body
//
// 1. Remove the margin in all browsers.
// 2. As a best practice, apply a default `background-color`.
// 3. Prevent adjustments of font size after orientation changes in iOS.
// 4. Change the default tap highlight to be completely transparent in iOS.

// scss-docs-start reboot-body-rules
body {
  margin: 0; // 1
  font-family: var(--#{$prefix}body-font-family);
  @include font-size(var(--#{$prefix}body-font-size));
  font-weight: var(--#{$prefix}body-font-weight);
  line-height: var(--#{$prefix}body-line-height);
  color: var(--#{$prefix}body-color);
  text-align: var(--#{$prefix}body-text-align);
  background-color: var(--#{$prefix}body-bg); // 2
  -webkit-text-size-adjust: 100%; // 3
  -webkit-tap-highlight-color: rgba($black, 0); // 4
}
// scss-docs-end reboot-body-rules

// Typography
//
// 1. Remove top margins from headings
//    By default, `<h1>`-`<h6>` all receive top and bottom margins. We nuke the top
//    margin for easier control within type scales as it avoids margin collapsing.

%heading {
  margin-top: 0; // 1
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-style: $headings-font-style;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: var(--#{$prefix}heading-color);
}

h1,
.h1 {
  @extend %heading;
  @include get-font-size(1);
}

h2,
.h2 {
  @extend %heading;
  @include get-font-size(2);
}

h3,
.h3 {
  @extend %heading;
  @include get-font-size(3);
}

h4,
.h4 {
  @extend %heading;
  @include get-font-size(4);
}

h5,
.h5 {
  @extend %heading;
  @include get-font-size(5);
}

h6,
.h6 {
  @extend %heading;
  @include get-font-size(6);
}

// Reset margins on paragraphs
//
// Similarly, the top margin on `<p>`s get reset. However, we also reset the
// bottom margin to use `rem` units instead of `em`.

p {
  margin-top: 0;
  margin-bottom: $paragraph-margin-bottom;
}

// Abbreviations
//
// 1. Add the correct text decoration in Chrome, Edge, Opera, and Safari.
// 2. Add explicit cursor to indicate changed behavior.
// 3. Prevent the text-decoration to be skipped.

abbr[title] {
  text-decoration: underline dotted; // 1
  cursor: help; // 2
  text-decoration-skip-ink: none; // 3
}

b,
strong {
  font-weight: $font-weight-bolder;
}

// Small
//
// Add the correct font size in all browsers

small {
  @include font-size($small-font-size);
}

// Mark

mark {
  padding: $mark-padding;
  color: var(--#{$prefix}highlight-color);
  background-color: var(--#{$prefix}highlight-bg);
}

// Sub and Sup
//
// Prevent `sub` and `sup` elements from affecting the line height in
// all browsers.

sub,
sup {
  position: relative;
  @include font-size($sub-sup-font-size);
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}

// Links

a {
  color: var(--bs-secondary);

  @media (hover: hover) {
    &:hover {
      //background: var(--bs-primary);
      color: color-mix(in srgb, black 40%, var(--bs-secondary));
    }
  }
}

// And undo these styles for placeholder links/named anchors (without href).
// It would be more straightforward to just use a[href] in previous block, but that
// causes specificity issues in many other styles that are too complex to fix.
// See https://github.com/twbs/bootstrap/issues/19402

a:not([href]):not([class]) {
  &,
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

// Code

pre,
code,
kbd,
samp {
  font-family: $font-family-code;
  @include font-size(1em); // Correct the odd `em` font sizing in all browsers.
}

// 1. Remove browser default top margin
// 2. Reset browser default of `1em` to use `rem`s
// 3. Don't allow content to break outside

pre {
  display: block;
  margin-top: 0; // 1
  margin-bottom: 1rem; // 2
  overflow: auto; // 3
  @include font-size($code-font-size);
  color: $pre-color;

  // Account for some code outputs that place code tags in pre tags
  code {
    @include font-size(inherit);
    color: inherit;
    word-break: normal;
  }
}

code {
  @include font-size($code-font-size);
  color: var(--#{$prefix}code-color);
  word-wrap: break-word;

  // Streamline the style when inside anchors to avoid broken underline and more
  a > & {
    color: inherit;
  }
}

kbd {
  padding: $kbd-padding-y $kbd-padding-x;
  @include font-size($kbd-font-size);
  color: $kbd-color;
  background-color: $kbd-bg;
  @include border-radius($border-radius-sm);

  kbd {
    padding: 0;
    @include font-size(1em);
    font-weight: $nested-kbd-font-weight;
  }
}

// Images and content

img,
svg {
  vertical-align: middle;
}
// 1. Textareas should really only resize vertically so they don't break their (horizontal) containers.

textarea {
  resize: vertical; // 1
}

// Hidden attribute
//
// Always hide an element with the `hidden` HTML attribute.

[hidden] {
  display: none !important;
}

img {
  width: 100%;
  height: auto;
}
