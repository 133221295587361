:root {
  --topbar-height: 132px;

  @include media-breakpoint-down(md) {
    --topbar-height: 56px;
  }
}

.menu--submenu-active {
  max-height: 100dvh;
  overflow: hidden;
}
