.fr-inner {
  color: $text-muted;
  margin: 5px 0 0;
  display: inline-block;
  font-size: 14px;
}

img {
  margin-top: rem(20px);
  margin-bottom: map-get($spacers, 4);
  max-width: 100%;
  max-height: 600px;
  object-fit: cover;
  border-radius: var(--bs-border-radius);

  @include media-breakpoint-up(lg) {
    margin-left: calc(var(--media-outset) * -1);
    margin-right: calc(var(--media-outset) * -1);
    width: calc(100% + var(--media-outset) * 2);
    max-width: none;
  }
}
