@each $name, $color in map-merge($theme-colors, $button-themes) {
  .button--#{$name} {
    --button-bg: #{$color};
  }
}

// Custom overrides
.button--light {
  --button-color: var(--bs-primary-800);
  --button-chip-bg: var(--bs-primary-100);

  &:not(.button-link):not(.button-outline):not(.button-icon) {
    fstr-icon {
      --button-bg: var(--bs-primary);
    }
  }

  @media (hover: hover) {
    &:hover {
      background-color: var(--bs-primary-200);

      &:not(.button-link):not(.button-outline):not(.button-icon) {
        fstr-icon {
          --button-bg: var(--bs-primary);
        }
      }
    }
  }
}

.button--primary-subtle {
  --button-color: var(--bs-primary);

  fstr-icon {
    --button-bg: var(--bs-primary);
  }

  @media (hover: hover) {
    &:hover {
      background-color: var(--bs-primary-200);
    }
  }
}

.button--secondary.button-link {
  --button-bg: #{$denim-800};

  fstr-icon {
    color: var(--bs-primary);
  }
}
