// Bootstrap
//! Warning: if you change these, also change $containers-not-fullwidth
$container-max-widths: (
  sm: 800px,
  md: 960px,
  lg: 1000px,
  xl: 1110px,
  xxl: 1320px,
);

$grid-gutter-width: 1rem;
$containers-not-fullwidth: map-remove($container-max-widths, 'sm', 'sm', 'md');
$container-padding-x: $grid-gutter-width;
$container-max-width: 500px;
// Other
//? Put own extra variables here
$container-sizes: (
  xxs: 500px,
  xs: 680px,
  xsm: 820px,
  sm: 960px,
  md: 1100px,
  xl: 1400px,
  xxl: 1600px,
);
