.embed-removed {
  background-color: var(--bs-background);
  text-align: center;
  padding: 2em 1em;
  margin: 2em 0;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up(lg) {
    margin-left: calc(var(--media-outset) * -1);
    margin-right: calc(var(--media-outset) * -1);
    width: calc(100% + var(--media-outset) * 2);
    max-width: none;
  }

  .fstr-cookie-btn.button {
    background: var(--bs-secondary);

    &:hover {
      background: color-mix(in srgb, var(--bs-secondary) 20%, #000);
    }
  }

  .embed-removed__text {
    font-weight: 600;
    color: var(--bs-background-text-color);
    margin-bottom: 1em;
  }
}
