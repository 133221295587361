.card {
  --bs-card-aspect-ratio: #{$card-aspect-ratio};
}

.card-img-overlay {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: column;
  min-height: 50cqw; // minimal aspect ratio
  //aspect-ratio: var(--bs-card-aspect-ratio, 1/0.75);
}

.card-img-background {
  @include abs(0);
}

.card-img {
  object-fit: cover;
  min-height: 30cqw;
}

test-fstr-image,
fstr-image {
  &.card-img {
    display: block;
    overflow: hidden;
  }

  &.card-img,
  &.card-img-top {
    img {
      @include border-top-radius(var(--#{$prefix}card-inner-border-radius));
    }
  }

  &.card-img,
  &.card-img-bottom {
    img {
      @include border-bottom-radius(var(--#{$prefix}card-inner-border-radius));
    }
  }
}
