fstr-icon {
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  @include size(1em);

  .icon {
    @include abs(0);
    margin: auto;
    fill: currentColor;
    stroke-width: 2;
    @include size(100%);

    &[class*='is--line-icon'] {
      stroke: currentColor;

      * {
        fill: transparent;
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.icon--spinning {
  .icon {
    animation: spin 2.2s linear infinite;
  }
}
