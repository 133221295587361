body {
  font-optical-sizing: auto;
}

%heading {
  word-break: auto-phrase;
  overflow-wrap: break-word;
  hyphens: auto; // For this to work html lang attribute must be set to the correct language
  font-optical-sizing: auto;
  text-wrap: balance;
}

h1,
.h1 {
  @extend %heading;
}

h2,
.h2 {
  @extend %heading;
}

h3,
.h3 {
  @extend %heading;
}

h4,
.h4 {
  @extend %heading;
}

h5,
.h5 {
  @extend %heading;
}

h6,
.h6 {
  @extend %heading;
}

.lead {
  @include media-breakpoint-down(md) {
    font-size: map-get($font-sizes, body-sm);
  }
}

.subtitle {
  @extend .h6;

  color: var(--bs-primary) !important;
  font-size: map-get($font-sizes, body-md) !important;
}
