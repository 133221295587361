:root {
  --button-bg: var(--bs-gray-300);
  --button-chip-bg: color-mix(in srgb, var(--button-color) 90%, transparent);
  --button-color: white;
  --button-size: #{rem($button-base-size)};
  --button-padding-x: #{rem($button-padding-x)};
  --button-border-width: 1px;
  --button-border-radius: #{$button-border-radius};
  --button-font-size: 1rem;
  --button-icon-size: 1.3em;
}

.button {
  // Icons in buttons
  --button-icon-inset: calc(var(--button-padding-x) / 2 * -0.75); // Reset some spacing for icons

  @extend %button-reset;

  position: relative;
  height: var(--button-size);
  padding: var(--button-padding-x);

  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  flex: 0 0 auto;

  font-size: var(--button-font-size);
  font-family: $button-font-family;
  font-weight: bold;
  line-height: 1;
  text-decoration: none;

  transition: all 200ms ease-in-out;
  cursor: pointer;

  background-color: var(--button-bg);
  color: var(--button-color);
  fill: currentColor;

  border-radius: var(--button-border-radius);
  border: var(--button-border-width) solid transparent;

  @media (hover: hover) {
    &:hover {
      background-color: color-mix(in srgb, var(--button-bg) #{100% - $button-hover-shade-amount}, black);
      color: var(--button-color);
    }
  }

  &:active {
    background-color: color-mix(in srgb, var(--button-bg) #{100% - $button-active-shade-amount}, black);
    color: var(--button-color);
  }

  &:disabled,
  &[disabled='true'] {
    pointer-events: none;
    opacity: 0.4;
  }

  > span + fstr-icon,
  > span + .chip,
  > span + .button__icon-inset {
    margin-right: var(--button-icon-inset);
    margin-left: 0;
    // padding: var(--button-padding-x);
  }

  > fstr-icon,
  > .chip,
  &__icon-inset {
    margin-left: var(--button-icon-inset);

    width: var(--button-icon-size);
    height: var(--button-icon-size);
    flex-shrink: 0;
  }
}

// Background color for icons
.button:not(.button-link):not(.button-outline):not(.button-icon) {
  --button-icon-size: 2em;

  gap: 0.7em;

  fstr-icon {
    background: var(--button-chip-bg);
    border-radius: var(--button-border-radius);
    color: var(--button-bg);
    overflow: hidden;
  }

  .icon {
    width: 70%;
    height: 70%;
  }

  &:hover {
    .is--line-icon-chevron-right,
    .is--line-icon-arrow-right,
    .is--icon-arrow-right {
      //transform: translate(100%, -100%);
      animation: icon-animation-right 0.6s 1 forwards;
    }

    .is--line-icon-chevron-left,
    .is--line-icon-arrow-left,
    .is--icon-arrow-left {
      //transform: translate(100%, -100%);
      animation: icon-animation-left 0.6s 1 forwards;
    }
  }
}

@keyframes icon-animation-right {
  0% {
    transform: translate(0, 0);
  }
  49% {
    transform: translate(100%, 0);
  }
  50% {
    transform: translate(-100%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes icon-animation-left {
  0% {
    transform: translate(0, 0);
  }
  49% {
    transform: translate(-100%, 0);
  }
  50% {
    transform: translate(100%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
