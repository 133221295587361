body { --css-icon-path: url('/assets/dist/svg/scss/svg/sprite.css.svg');

    --icon-alert-circle-aspect-ratio: 25 / 24;
    --icon-alert-circle-relative-pos: 0 0;
    --icon-alert-octagon-aspect-ratio: 25 / 24;
    --icon-alert-octagon-relative-pos: 0 1.2752391073326248%;
    --icon-alert-triangle-aspect-ratio: 25 / 24;
    --icon-alert-triangle-relative-pos: 0 2.5504782146652496%;
    --icon-arrow-down-aspect-ratio: 25 / 24;
    --icon-arrow-down-relative-pos: 0 3.8257173219978746%;
    --icon-arrow-left-aspect-ratio: 25 / 24;
    --icon-arrow-left-relative-pos: 0 5.100956429330499%;
    --icon-arrow-right-aspect-ratio: 25 / 24;
    --icon-arrow-right-relative-pos: 0 6.376195536663125%;
    --icon-arrow-up-aspect-ratio: 25 / 24;
    --icon-arrow-up-relative-pos: 0 7.651434643995749%;
    --icon-check-aspect-ratio: 24 / 24;
    --icon-check-relative-pos: 0 8.926673751328375%;
    --icon-chevron-down-aspect-ratio: 25 / 24;
    --icon-chevron-down-relative-pos: 0 10.201912858660998%;
    --icon-chevron-left-aspect-ratio: 25 / 24;
    --icon-chevron-left-relative-pos: 0 11.477151965993624%;
    --icon-chevron-right-aspect-ratio: 25 / 24;
    --icon-chevron-right-relative-pos: 0 12.75239107332625%;
    --icon-chevron-up-aspect-ratio: 25 / 24;
    --icon-chevron-up-relative-pos: 0 14.027630180658873%;
    --icon-external-link-aspect-ratio: 25 / 24;
    --icon-external-link-relative-pos: 0 15.302869287991498%;
    --icon-facebook-aspect-ratio: 200 / 200;
    --icon-facebook-relative-pos: 0 18.2883939038687%;
    --icon-instagram-aspect-ratio: 25 / 25;
    --icon-instagram-relative-pos: 0 27.219564061669324%;
    --icon-line-youtube-aspect-ratio: 24 / 25;
    --icon-line-youtube-relative-pos: 0 28.548644338118024%;
    --icon-link-aspect-ratio: 25 / 24;
    --icon-link-relative-pos: 0 29.861849096705633%;
    --icon-linkedin-aspect-ratio: 15 / 15;
    --icon-linkedin-relative-pos: 0 30.988894764674775%;
    --icon-loader-aspect-ratio: 28 / 30;
    --icon-loader-relative-pos: 0 32.0362473347548%;
    --icon-mail-aspect-ratio: 25 / 24;
    --icon-mail-relative-pos: 0 33.52816153028693%;
    --icon-play-aspect-ratio: 25 / 25;
    --icon-play-relative-pos: 0 34.82190324295588%;
    --icon-quote-aspect-ratio: 24 / 24;
    --icon-quote-relative-pos: 0 36.13177470775771%;
    --icon-spinner-aspect-ratio: 200 / 200;
    --icon-spinner-relative-pos: 0 41.26611957796014%;
    --icon-twitter-aspect-ratio: 200 / 200;
    --icon-twitter-relative-pos: 0 52.989449003517%;
    --icon-x-aspect-ratio: 25 / 24;
    --icon-x-relative-pos: 0 58.66099893730075%;
    --icon-x-octagon-aspect-ratio: 25 / 24;
    --icon-x-octagon-relative-pos: 0 59.93623804463337%;
    --line-icon-alert-circle-aspect-ratio: 25 / 24;
    --line-icon-alert-circle-relative-pos: 0 61.211477151965994%;
    --line-icon-alert-octagon-aspect-ratio: 25 / 24;
    --line-icon-alert-octagon-relative-pos: 0 62.48671625929862%;
    --line-icon-alert-triangle-aspect-ratio: 25 / 24;
    --line-icon-alert-triangle-relative-pos: 0 63.76195536663124%;
    --line-icon-arrow-down-aspect-ratio: 25 / 24;
    --line-icon-arrow-down-relative-pos: 0 65.03719447396386%;
    --line-icon-arrow-left-aspect-ratio: 25 / 24;
    --line-icon-arrow-left-relative-pos: 0 66.31243358129649%;
    --line-icon-arrow-right-aspect-ratio: 25 / 24;
    --line-icon-arrow-right-relative-pos: 0 67.58767268862911%;
    --line-icon-arrow-up-aspect-ratio: 25 / 24;
    --line-icon-arrow-up-relative-pos: 0 68.86291179596174%;
    --line-icon-arrow-up-right-aspect-ratio: 25 / 25;
    --line-icon-arrow-up-right-relative-pos: 0 70.17543859649123%;
    --line-icon-check-aspect-ratio: 24 / 24;
    --line-icon-check-relative-pos: 0 71.46652497343251%;
    --line-icon-chevron-down-aspect-ratio: 25 / 24;
    --line-icon-chevron-down-relative-pos: 0 72.74176408076514%;
    --line-icon-chevron-left-aspect-ratio: 25 / 24;
    --line-icon-chevron-left-relative-pos: 0 74.01700318809777%;
    --line-icon-chevron-right-aspect-ratio: 25 / 24;
    --line-icon-chevron-right-relative-pos: 0 75.2922422954304%;
    --line-icon-chevron-up-aspect-ratio: 25 / 24;
    --line-icon-chevron-up-relative-pos: 0 76.56748140276302%;
    --line-icon-external-link-aspect-ratio: 25 / 24;
    --line-icon-external-link-relative-pos: 0 77.84272051009565%;
    --line-icon-facebook-aspect-ratio: 25 / 24;
    --line-icon-facebook-relative-pos: 0 79.11795961742827%;
    --line-icon-instagram-aspect-ratio: 24 / 25;
    --line-icon-instagram-relative-pos: 0 80.43593833067517%;
    --line-icon-link-aspect-ratio: 25 / 24;
    --line-icon-link-relative-pos: 0 81.72157279489905%;
    --line-icon-linkedin-aspect-ratio: 24 / 24;
    --line-icon-linkedin-relative-pos: 0 82.99681190223167%;
    --line-icon-mail-aspect-ratio: 25 / 24;
    --line-icon-mail-relative-pos: 0 84.2720510095643%;
    --line-icon-phone-aspect-ratio: 25 / 24;
    --line-icon-phone-relative-pos: 0 85.54729011689692%;
    --line-icon-x-aspect-ratio: 24 / 24;
    --line-icon-x-relative-pos: 0 86.82252922422954%;
    --line-icon-x-octagon-aspect-ratio: 25 / 24;
    --line-icon-x-octagon-relative-pos: 0 88.09776833156216%;
    --line-icon-youtube-aspect-ratio: 24 / 24;
    --line-icon-youtube-relative-pos: 0 89.37300743889479%;
    --wuestman-icon-aspect-ratio: 156.08 / 200;
    --wuestman-icon-relative-pos: 0 100%;

}