em,
i {
  font-style: italic;
}

strong {
  font-weight: 800;
}

//@each $name, $size in $markdown-font-sizes {
//  * + #{$name},
//  * + .#{$name} {
//    margin-top: map-get($spacers, 6);
//  }

//  #{$name},
//  .#{$name} {
//    font-size: rem($size);
//    margin-top: 1.25em;
//    margin-bottom: 0.5em;
//    font-weight: 500;
//    color: var(--bs-primary);
//  }
//}

//@include headings {
//  margin-top: 1.25em;
//  font-weight: 500;
//}

p + ol,
p + ul {
  margin-top: -1rem;
}

ul,
ol {
  list-style: outside;
  padding-inline-start: map-get($spacers, 4);
  display: inline-block;
  margin-bottom: rem(20px);
  width: 100%;

  li {
    display: list-item;
    list-style-type: initial;
    //font-weight: 400;
  }

  ul {
    li {
      list-style-type: circle;

      ul li {
        list-style-type: square;

        ul li {
          list-style: disc;
        }
      }
    }
  }

  ul,
  ol {
    margin-bottom: 1ex;
  }
}

ol li {
  list-style-type: decimal;
}

hr {
  margin: 1.5em 0;
}

p {
  margin-bottom: 3ex;
}

p,
table,
ul,
ol,
dl,
blockquote {
  max-width: 800px;
}

pre {
  white-space: pre-line;
  font-family: monospace;
  background: var(--bs-gray-100);
  padding: 1em;
  border-radius: 7px;
  margin-bottom: 1.5em;
}

.button + .button {
  margin-inline-start: 0.5em;
}
