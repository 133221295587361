$checkbox-svg: url('data:image/svg+xml,<svg width="18" height="13" viewBox="0 0 18 13" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.7071 0.292893C18.0976 0.683417 18.0976 1.31658 17.7071 1.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071L0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289C0.683417 5.90237 1.31658 5.90237 1.70711 6.29289L6 10.5858L16.2929 0.292893C16.6834 -0.0976311 17.3166 -0.0976311 17.7071 0.292893Z" fill="#{$info}" /></svg>');

//&.list--check-list {
ul:not(:has(ul)):not(li > ul) {
  padding-inline-start: 0;

  li {
    --marker-size: 33px;

    list-style: none;
    margin-bottom: 1ex;
    padding-left: calc(var(--marker-size) + 5px);
    //display: flex;

    &:before {
      content: '';
      display: inline-block;
      @include size(1.2rem);
      background-image: escape-svg($checkbox-svg);
      background-size: contain;
      background-repeat: no-repeat;
      //transform: translateY(9px);
      // min-width: 1.5rem;
      background-color: unset;
      margin-right: 1.6ex;
      margin-left: calc(var(--marker-size) * -1);
      vertical-align: middle;
      flex-shrink: 0;
      margin-top: 0.2ex;
    }
  }
}

ul ul li {
  &::marker {
    color: var(--bs-primary);
  }
}
