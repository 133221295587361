// Bootstrap
$spacer: 1rem; //16px
$spacers: (
  0: 0,
  // 0px
  1: $spacer * 0.25,
  // 4px
  2: $spacer * 0.5,
  // 8px
  3: $spacer,
  // 16px
  4: $spacer * 1.5,
  //  24px
  5: $spacer * 2,
  // 48px
  6: $spacer * 3,
  // 64px
  7: $spacer * 4,
  // 80px
  8: $spacer * 5,
  // 96px
  9: $spacer * 6,
  // 112px
);

$enable-negative-margins: true;

// Other
//? Put own extra variables here
