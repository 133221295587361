body {
  @include tint-css-variables('primary');
  @include tint-css-variables('secondary');

  --bs-primary-100: #{$primary-100};

  --media-outset: 0px;

  @include media-breakpoint-up(lg) {
    --media-outset: 100px;
  }

  @include media-breakpoint-up(xxl) {
    --media-outset: 150px;
  }
}
