@each $name, $value in $button-sizes {
  .button--#{$name} {
    --button-size: #{rem($value)};
    --button-padding-x: #{rem(($value * 0.2) + 5)};
    --button-font-size: #{map-get($font-sizes, 'body-' + $name)};
    --button-icon-size: #{rem($value * 0.5)};

    &.button:not(.button-link):not(.button-outline):not(.button-icon) {
      --button-icon-size: #{rem($value * 0.75)};
    }
  }
}

.button--xs {
  --button-padding-x: 9px;
  --button-icon-size: 1.3rem;
  gap: 8px;
}
