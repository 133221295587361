.link-reset {
  text-decoration: none;
  color: currentColor;

  &:focus-visible {
    outline: 2px solid var(--bs-warning);
    outline-offset: 3px;
  }
}

.text-primary-subtle {
  color: var(--bs-primary-bg-subtle) !important;
}

//@for $i from 1 through 9 {
//  .text-primary-#{$i}00 {
//    color: var(--bs-primary-#{$i}00) !important;
//  }
//}

// for 3 items
@for $i from 1 through 5 {
  .max-lines-#{$i} {
    display: -webkit-box;
    -webkit-line-clamp: $i;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.text-truncate {
  overflow: hidden !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.text-bg-secondary {
  .h5,
  h5,
  .h6,
  h6 {
    --bs-heading-color: var(--bs-primary);
  }
}

.text-bg-success {
  color: white !important; // client feedback
}
