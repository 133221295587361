p:has(.fr-video) {
  max-width: none;
}

.fr-video {
  position: relative;
  display: block;
  width: 100%;

  padding-top: 56.25%;
  margin-bottom: 2ex;

  @include media-breakpoint-up(lg) {
    margin-left: calc(var(--media-outset) * -1);
    margin-right: calc(var(--media-outset) * -1);
    width: calc(100% + var(--media-outset) * 2);
    max-width: none;
    // padding-top: calc(56.25% + var(--media-outset) * 2);
    aspect-ratio: 16 / 9;
  }

  iframe {
    @include abs(0, 0, 0, 0);
    @include size(100%);
    border-radius: var(--bs-border-radius);
  }
}

iframe,
object,
embed {
  width: 100%;
}
