@import './functions/all';
@import './mixins/all';

@import './bootstrap-config';

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import 'bootstrap/scss/utilities/api';

// 6. Optionally include any other parts as needed
@import './bootstrap-components';

// SVG helpers
@import './src/assets/dist/svg/scss/_svg-css.scss';

// Vendor
@import './material/all';
@import './node_modules/swiper/swiper.scss';
//@import './node_modules/modules/autoplay.scss';
//@import './node_modules/modules/free-mode.scss';

// Themes
@import 'themes/all';

// Applying config to base tags etc.
@import 'base/all';
@import 'utils/all';
@import 'components/all';
@import './angular-modules';

// hide powerd by froala
[data-f-id='pbf'] {
  display: none;
}
